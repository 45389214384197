const firebaseConfig = {
    apiKey: "AIzaSyCXoJcs59J12nihP1GwK31pKda4q9rJsZ0",
    authDomain: "dash-skatebunny.firebaseapp.com",
    projectId: "dash-skatebunny",
    storageBucket: "dash-skatebunny.appspot.com",
    messagingSenderId: "77625640925",
    appId: "1:77625640925:web:a3be2526a1cfb8ac5b1834",
    measurementId: "G-0CEDSVKH9Q",
};

export default firebaseConfig;