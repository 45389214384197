import React, { useState, useEffect } from "react";
import "../_styles.scss";

import { Link } from "react-router-dom";
import { FaEnvelope, FaPhone, FaInstagram, FaLinkedin, FaYoutube, FaTelegram, FaWhatsapp, FaFacebook } from "react-icons/fa";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";

function Footer() {

    // Getting current year to show in the footer
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // State declarations for various data
    const [BDName, setBDName] = useState("");
    const [P4T0, setP4T0] = useState("");
    const [operationHours, setOperationHours] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const { userClaims } = useAuth();

    // useEffect hook to fetch data from Firebase firestore
    useEffect(() => {
        const fetchData = async () => {
            if (userClaims) {
                const docRefBDName = doc(db, userClaims, "usergi");
                const docSnapBDName = await getDoc(docRefBDName);
                if (docSnapBDName.exists()) {
                    const dataBDName = docSnapBDName.data();
                    setBDName(dataBDName.BDName);
                    setUserDetails(dataBDName);
                } else {
                    console.log("No such document!");
                }

                const docRefText = doc(db, userClaims, "page-4");
                const docSnapText = await getDoc(docRefText);
                if (docSnapText.exists()) {
                    const dataText = docSnapText.data();
                    let rawText = dataText?.texts["0"];
                    if (rawText.length > 300) {
                        setP4T0(rawText.slice(0, 300) + "...");
                    } else {
                        setP4T0(rawText);
                    }
                } else {
                    console.log("No such document!");
                }

                const docRefOperationHours = doc(db, userClaims, "operation-hours");
                const docSnapOperationHours = await getDoc(docRefOperationHours);
                if (docSnapOperationHours.exists()) {
                    const dataOperationHours = docSnapOperationHours.data();
                    setOperationHours(dataOperationHours);
                } else {
                    console.log("No such document!");
                }
            }
        };
        if (userClaims) {
            fetchData();
        }
    }, [userClaims]);

    // Social media links and their icons
    const connectWithUs = [
        { link: `mailto:${userDetails.BEmail}`, icon: FaEnvelope },
        { link: `tel:${userDetails.BPhone1 || userDetails.BPhone2}`, icon: FaPhone },
        { link: userDetails.Facebook, icon: FaFacebook },
        { link: userDetails.Instagram, icon: FaInstagram },
        { link: userDetails.LinkedIn, icon: FaLinkedin },
        { link: userDetails.Telegram, icon: FaTelegram },
        { link: userDetails.WhatsApp, icon: FaWhatsapp },
        { link: userDetails.YouTube, icon: FaYoutube }
    ].filter(({ link }) => link); // filter out any fields that don't exist

    // Weekdays to display operation hours
    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Format address
    const address = [
        BDName,
        userDetails.BAddress,
        userDetails.BCity,
        userDetails.BProvince,
        userDetails.BPostal,
        userDetails.BCountry
    ].filter(Boolean).join(", ");

    return (
        <>
            <div className="p-0 m-0 w-100">
                {userDetails.BMapUrl && (
                    <>
                        <div className="p-0 m-0 w-100">
                            <a
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="p-0 m-0 mx-auto col-12 text-center map-address"
                            >
                                {address}
                            </a>
                        </div>
                        <div className="p-0 m-0 mx-auto col-12 align-items-center" style={{ height: "350px" }}>
                            <iframe
                                title="Business Location"
                                src={userDetails.BMapUrl}
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            />
                        </div>
                    </>
                )}
                <div className="the-border-1" />
                <div className="p-2 p-lg-4 m-0 mx-auto col-12 col-xl-11 align-items-center justify-content-between row">
                    <div className="px-3 py-4 py-lg-0 m-0 text-start justify-content-start align-self-start col-12 col-lg-4">
                        <h4 className="color2">{BDName}</h4>
                        <div className="mb-3" dangerouslySetInnerHTML={{ __html: P4T0 }} />
                        <Link to="/about-us" className="btn btn1 w-auto align-items-center d-inline-flex">Read More About Us</Link>
                    </div>
                    <div className="px-3 py-4 py-lg-0 m-0 text-start justify-content-start align-self-start col-12 col-lg-4">
                        <h4 className="mb-3 color2">Working Hours</h4>
                        {weekdays.map(day => operationHours[day] && <p key={day} className="dayHours"><span className="color3">{day}:</span><span>{operationHours[day]}</span></p>)}
                    </div>
                    <div className="px-3 py-4 py-lg-0 m-0 text-start justify-content-start align-self-start col-12 col-lg-4">
                        <h4 className="color2">Connect With Us</h4>
                        <div className="p-0 m-0 w-100 row">
                            {connectWithUs.map(({ link, icon: Icon }, i) => (
                                <a key={i} href={link} target="_blank" rel="noopener noreferrer" className="p-2 m-2 btn btn1 w-auto align-items-center d-inline-flex">
                                    <Icon size={25} className="m-0" />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="the-border-1" />
                <div className="p-0 px-3 py-2 m-0 w-100 align-items-center justify-content-between row">
                    <div className="p-0 p-2 m-0 col-12 col-md-3 text-center">
                        <Link to="/privacy-policy" className="btn btn1 w-auto align-items-center d-inline-flex m-2">Privacy Policy</Link>
                    </div>
                    <div className="p-0 m-0 col-12 col-md-6 text-center">
                        <div className="p-0 m-0 w-auto">
                            <Link className="p-0 m-0 w-auto" to="https://skatebunny.ca">
                                <p className="p-0 m-0 w-auto logo-text">Powered By <img src="/logo/bombsam.svg" className="footer-logo w-100 h-100" alt="Bombsam Web Inc Logo" /> Bombsam</p>
                            </Link>
                        </div>
                    </div>
                    <div className="p-0 p-2 m-0 col-12 col-md-3 text-center">
                        <p className="p-0 m-0 w-auto">© {currentYear}, {BDName}. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;
